import isEmpty from "./isEmpty";


export const blurDate = (value) => {
    if(isEmpty(value)){
        return "01/01/0001";
    }else{
        var strArray = value.split('');
        for(var i=0; i<strArray.length; i++){
            if(i!=0 && i!=1 && strArray[i] != "/" ){
                strArray[i] = "*";
            }
        }

        return strArray.join('');
    }  
  };
  