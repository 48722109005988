import React, { useState } from "react";
import { connect } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";
import classnames from "classnames";
import { useHistory } from "react-router-dom";

import CustomButton from "../../components/UIs/CustomButton/CustomButton";
import FormInput from "../../components/UIs/FormInput/FormInput.ui";
import settings from "../../config/settings";

// import { getOTP_, setNumber } from "../../store/reducers/user/user.actions";
import { loginAsync, getOTP_, setNumber } from "../../store/reducers/user/user.actions";

import "./auth.styles.scss";
import { FormattedMessage } from "react-intl";

const initialValues = {
    phoneNumber: "",
    code: "",
};

const AuthPage = ({ getOTP_, loginAsync, setNumber }) => {

    const [state, setState] = useState({
        phoneNumber: "",
        code: "",
        errors: {},
        publicKey: { loading: true, error: false },
    });
    const [loading, setLoading] = useState(false);
    const history = useHistory();

    const validationSchema = Yup.object({
        phoneNumber: Yup.string()
        .max(14, "Le nombre de caractères maximum est de 14")
        .required("Le numéro est requis")
        .matches(
            settings.regexMsisdn,
        "Veuillez entrer un numéro de téléphone Orange Valide"
        ),
          code: Yup.string()
          .required("Le Code est requis"),
      });
    
    const handleSubmit = (values) => {
        setNumber(values.phoneNumber);
        setLoading(true);
        loginAsync(values.phoneNumber, values.code)
        .then(() => {
            setLoading(false);
            history.push('/');
        }).catch(() => {
            setLoading(false);
        });
    }

    const telephoneCheck = (str) => {
        return settings.regexMsisdn.test(str);
    }

    const handleGetOTP = () => {
        if(telephoneCheck(state.phoneNumber)){
            getOTP_(state.phoneNumber);
        }

        // getOTP(state.phoneNumber);
    }

    return (
        <div className="auth-content-box">
            
            <div className="auth-content-box">
                <h2 className="auth-content-title">Formulaire digital KYC</h2>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                        enableReinitialize={true}
                        className="form-auth"
                    >
                        {(formik) => {
                            return(
                                <form onSubmit={formik.handleSubmit}>
                                    <div className="form-group">
                                        <FormInput
                                            name="phoneNumber"
                                            label={<FormattedMessage id="app.form.number.label" />}
                                            onChange={(e) => {formik.setFieldValue('phoneNumber', e.target.value);
                                                setState({ ...state, phoneNumber: e.target.value })
                                            }}
                                            onBlur={formik.handleBlur}
                                            required
                                            value={state.phoneNumber}
                                            className={classnames("form-control custom-input", {
                                                "is-invalid": formik.touched.phoneNumber && formik.errors.phoneNumber,
                                            })}
                                        />
                                        {formik.touched.phoneNumber && formik.errors.phoneNumber && (
                                            <small className="form-text text-danger">
                                            {formik.errors.phoneNumber}
                                            </small>
                                        )}
                                    </div>
                                    
                                    <span style={{fontSize: '0.9em'}}>
                                        <span onClick={() => handleGetOTP()} className="sendCode-button">
                                            <FormattedMessage id="app.form.message2" />
                                        </span>
                                        {" "} <FormattedMessage id="app.form.message3" />
                                    </span>
                                    <br /><br />
                                    <div className="form-group">
                                        <FormInput
                                            name="code"
                                            type="password"
                                            label={<FormattedMessage id="app.input.code" />}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            required
                                            value={formik.values.code}
                                            className={classnames("form-control custom-input", {
                                                "is-invalid": formik.touched.code && formik.errors.code,
                                            })}
                                        />
                                        {formik.touched.code && formik.errors.code && (
                                            <small className="form-text text-danger">
                                            {formik.errors.code}
                                            </small>
                                        )}
                                        
                                    </div>
                                        
                                    <CustomButton disabled={loading} type="submit">
                                        {!loading ? "Se connecter" : "Connexion..."}
                                    </CustomButton>
                                </form>
                            );
                        }}
                        
                    </Formik>

            </div>
        </div>
    )
}


const mapDispatchToProps = (dispatch) => ({
    getOTP_: (msisdn) => dispatch(getOTP_(msisdn)),
    loginAsync: (msisdn, code) => dispatch(loginAsync(msisdn, code)),
    setNumber: (msisdn) => dispatch(setNumber(msisdn)),
})
export default connect(null, mapDispatchToProps)(AuthPage);