import React from "react";
import { FormattedMessage } from "react-intl";
import classnames from 'classnames';

import AlertMessage from "../AlertMessage/AlertMessage.ui";
import ErrorLoading from "../ErrorLoading/ErrorLoading.ui";

import "./InfosIdentification.styles.scss";

import { Rings } from  'react-loader-spinner';

import isEmpty from "../../../utils/isEmpty";
import { blurDate } from "../../../utils/blurDate";

const InfosIdentification = ({ 
        typeInfos, 
        name, 
        idCNI, 
        dateNaissance, 
        numberLines, 
        loading, 
        isError,
        dateExpirationCNI,
        conform
     }) => {
    return (
        <>
            <div className='info-detail-header'><FormattedMessage id="app.identity.title" /> {typeInfos}</div>
                {
                    loading ?
                    <>
                        <div className='rings-loader'>
                            <Rings ariaLabel="loading-indicator" color="black"/>
                        </div>
                        <span style={{fontStyle: 'italic', fontSize:'0.9em', textAlign: 'center'}}><FormattedMessage id="app.waiting.message" /></span>
                    </>                    
                    :
                    isError ?
                    <>
                    {
                        isEmpty(name) && isEmpty(idCNI)
                        ?
                        <ErrorLoading
                            errorText="app.noAccount.message"
                        />
                        :
                        <>
                            <br/>
                            <div 
                                className={classnames("info-line", {
                                    "not-ok": !conform.name,
                                    "ok": conform.name
                                })}
                            >
                                <span className='info-label'><FormattedMessage id="app.name.label" /> : </span> {name?.toUpperCase()}
                            </div><br/>
                            <div
                                className={classnames("info-line", {
                                    "not-ok": !conform.idCard,
                                    "ok": conform.idCard
                                })}
                            >
                                <span className='info-label'><FormattedMessage id="app.cni.label" /> : </span> {idCNI}
                            </div><br/>
                            <div
                                className={classnames("info-line", {
                                    "not-ok": !conform.birthDate,
                                    "ok": conform.birthDate
                                })}
                            >
                                <span className='info-label'><FormattedMessage id="app.dateNaissance.label" /> : </span> {blurDate(dateNaissance)}
                            </div><br/>
                            <div
                                className={classnames("info-line", {
                                    "not-ok": !conform.expiredDateIdCard,
                                    "ok": conform.expiredDateIdCard
                                })}
                            >
                            <span className='info-label'><FormattedMessage id="app.cni.expirationDate.label" /> : </span> {dateExpirationCNI}
                            </div>
                            {/*<br/>
                             <div
                                className={classnames("info-line", {
                                    "not-ok": !(conform.nbrPhoneLine || typeInfos == "OM"),
                                    "ok": (conform.nbrPhoneLine || typeInfos == "OM")
                                })}
                            >
                                <span className='info-label'>
                                    <FormattedMessage id={typeInfos == "OM"? "app.numberLine.om.label": "app.numberLine.label"} /> : 
                                    </span> {numberLines == -1 ? <FormattedMessage id="app.nbrLine.notfound" />: numberLines}
                            </div> */}
                        </>
                        
                    }
                        
                    </>
                    :
                    <AlertMessage message={<FormattedMessage id="app.error.message" />}/>
                    
                }
                
        </>
    )
}

export default InfosIdentification;