import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import userReducer from "./user/user.reducer";
import identificationReducer from "./identification/identification.reducer";

export const persistConfig = {
    key: "root",
    storage,
    whitelist: [
        "identification",
        "user"
    ]
};

const topReducer = combineReducers({
    identification: identificationReducer,
    user: userReducer,
});

const rootReducer = (state, action) => {
    return topReducer(state, action);
};

export default persistReducer(persistConfig, rootReducer);
// export default rootReducer;