import React, { useEffect, useState } from 'react';
import { Container } from 'reactstrap';
import NavMenu from './NavMenu';
import { connect } from 'react-redux';

import { setLang } from '../store/reducers/user/user.actions';


const Layout = (props) => {
  const [langue, setLangue] = useState('fr');

  useEffect(() => {
    console.log("langue ", langue);
    props.setLang(langue);
  }, [langue])

    return (
      <div className='container'>
        <NavMenu /><br /><br /><br /><br />
        <div className='row'>
            <div className='col-10'></div>
            <div className='col-2 input-group mb-3'>
            {/* <label class="input-group-text" for="inputGroupSelect01">Langue:</label>  */}
                <select className="form-select" value={langue} onChange={(event) => setLangue(event.target.value) } id="inputGroupSelect01">
                    <option value="fr">FR</option>
                    <option value="en">EN</option>
                </select>
            </div>
          </div><br />
        <div style={{marginLeft: '4%', marginRight: '4%'}}>
          {props.children}
        </div>
      </div>
    );
}

const mapDispatchToProps = (dispatch) => ({
  setLang: (lang) => dispatch(setLang(lang))
})

export default connect(null, mapDispatchToProps)(Layout);
