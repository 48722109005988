import React from "react";
import { FormattedMessage } from "react-intl";

import {
  Button,
  ErrorLoadingContainer,
  ErrorText,
} from "./ErrorLoading.styles";

const ErrorLoading = ({
  errorText = "",
  actionText = "",
  actionClick,
}) => {
  return (
    <ErrorLoadingContainer>{console.log("ErrorText ", errorText)}
      <img
        src={require("./error-content-illustration.svg").default}
        className="img-fluid"
        // style={{ maxWidth: "428px" }}
      />
      {errorText.length > 0 && <ErrorText>{<FormattedMessage id={errorText} />}</ErrorText>}
      {actionText.length > 0 && (
        <Button onClick={actionClick}>{actionText}</Button>
      )}
    </ErrorLoadingContainer>
  );
};

export default ErrorLoading;
