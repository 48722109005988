import keycloak from '../../../utils/keycloak';
import setAuthToken from '../../../utils/setAuthToken';
import userActionTypes from './user.types';
import API from '../../../utils/API';
import { toast } from 'react-toastify';
import axios from 'axios';
import settings from '../../../config/settings';

const requestAuthenticationStart = () => ({
    type: userActionTypes.REQUEST_AUTHENTICATION_START,
});
  
const requestAuthenticationFailure = () => ({
    type: userActionTypes.REQUEST_AUTHENTICATION_FAILURE,
});
  
const requestAuthenticationSuccess = ({ keycloak, authenticated }) => ({
    type: userActionTypes.REQUEST_AUTHENTICATION_SUCCESS,
    payload: { Keycloak: keycloak, authenticated }
});

const setToken = (token) => ({
    type: userActionTypes.SET_TOKEN,
    payload: token
});

export const setLang = (lang) => (dispatch) => {
    dispatch({
        type: userActionTypes.SET_LANG,
        payload: lang
    });
};

export const setNumber = (number) => (dispatch) => {
    dispatch({
        type: userActionTypes.SET_NUMBER,
        payload: number
    })
};

export const requestAuthenticationAsync = () => (dispatch) => {
    dispatch(requestAuthenticationStart());
  
    keycloak.init({
        onLoad: "login-required",
        checkLoginIframe: false,
        promiseType: "native"
    })
    .then((authenticated) => {
        if (authenticated) {
            setAuthToken(keycloak.token);
            dispatch(requestAuthenticationSuccess({ keycloak, authenticated }));
        }
    })
    .catch((err) => dispatch(requestAuthenticationFailure()));
};

export const requestAuthentificationLogout = () => ({
    type: userActionTypes.REQUEST_AUTHENTIFICATION_LOGOUT,
    payload: null,
});
  
export const requestAuthentificationLogoutAsync = () => (dispatch) => {
    keycloak.logout();
    setAuthToken(null);
    dispatch(requestAuthentificationLogout());
};

export const getOTP_ = (msisdn) => (dispatch) => {
    return new Promise ((resolve, reject) => {
        axios.get(`${settings.baseUrl}/Infos/OTP?msisdn=${msisdn}`)
        .then((res) => {
            toast.success('Vous allez recevoir un code valable pendant 5 minutes et utilisable une seule fois!');
        }).catch((error) => {
            toast.error('Une erreur est survenue, veuillez réessayer.');
            reject();
        })
    })
}

export const loginAsync = (msisdn, code) => (dispatch) => {
    return new Promise((resolve, reject) => {
        axios.post(`${settings.baseUrl}/Infos/Login`, {
            msisdn: msisdn,
            code:  code
        }).then((res) => {
            dispatch(setToken(res.data));
            resolve(res);
        }).catch((error) => {
            console.log("error", error.response);
            if(error.response.status == 400){
                toast.error(error.response.data);
            }else{
                toast.error('Une erreur est survenue, veuillez réessayer.');
            }
            reject();
        })

    })
}

export const logoutAsync = (msisdn, code) => (dispatch) => {
    return new Promise((resolve, reject) => {
        axios.get(`${settings.baseUrl}/Infos/Logout?msisdn=${msisdn}`)
        .then((res) => {
            console.log("res ", res);
            dispatch(setToken(null));
            resolve(res);
        }).catch((error) => {
            console.log("error", error.response);
            if(error.response.status == 400){
                toast.error(error.response.data);
            }
            reject();
        })

    })
}