export const ENVIRONEMENT = {
  SANDBOX: 'SANDBOX',
  LIVE: 'LIVE'
};


export const statusConfig = {
  STATUS_CONFORM: 1,
  STATUS_NOT_CONFORM: 2,
  STATUS_CNI: 3
};