import { createSelector } from 'reselect';

const selectIdentification = state => state.identification;

export const selectInfosIdentification = createSelector(
    [selectIdentification],
    (identification) => identification.infosIdentification
);

export const selectInfosTelco = createSelector(
    [selectIdentification],
    (identification) => identification.infosTelco
);

export const selectInfosOm = createSelector(
    [selectIdentification],
    (identification) => identification.infosOm
);

export const selectInfosStatus = createSelector(
    [selectIdentification],
    (identification) => identification.infosStatus
);