import identificationActionTypes from "./identification.types";
import API from "../../../utils/API";
import isDev from "../../../utils/process";
import settings from "../../../config/settings";

import { toast } from 'react-toastify';

const getIdentifications = (identifications) => {
    return {
        type: identificationActionTypes.GET_INFOS_CLIENT,
        payload: identifications 
    }
};

const getInfosTelco = (infosTelco) => {
    return {
        type: identificationActionTypes.GET_INFOS_TELCO,
        payload: infosTelco
    }
};

const getInfosOM = (infosOM) => {
    return {
        type: identificationActionTypes.GET_INFOS_OM,
        payload: infosOM
    }
};

const getStatus = (status) => {
    return {
        type: identificationActionTypes.GET_STATUS,
        payload: status
    }
};

export const getInfosTelcoAsync = (msisdn) => (dispatch) => {
    return new Promise((resolve, reject) => {
        API.get(`/Infos/TelcoInfos?msisdn=${msisdn}`)
        .then((res) => {
            console.log("res ", res);
            dispatch(getInfosTelco(res.data));
            resolve(res);
        }).catch((error) => {
            // toast.error('Une erreur est survenue, veuillez réessayer.');
            reject(error);
        })

    })
}

export const getInfosOMAsync = (msisdn) => (dispatch) => {
    return new Promise((resolve, reject) => {
        API.get(`/Infos/OMInfos?msisdn=${msisdn}`)
        .then((res) => {
            console.log("res ", res);
            dispatch(getInfosOM(res.data));
            resolve(res);
        }).catch((error) => {
            // toast.error('Une erreur est survenue, veuillez réessayer.');
            reject(error);
        })

    })
}

export const getStatusAsync = (msisdn) => (dispatch) => {
    return new Promise((resolve, reject) => {
        API.get(`/Infos/identification?msisdn=${msisdn}`)
        .then((res) => {
            console.log("res ", res);
            dispatch(getStatus(res.data));
            resolve(res);
        }).catch((error) => {
            // toast.error('Une erreur est survenue, veuillez réessayer.');
            reject(error);
        })
    })
}

export const getIdentificationsAsync = (msisdn) => (dispatch) => {
    const uriTelco = `/Infos/TelcoInfos?msisdn=${msisdn}`;
    const uriOm = `/Infos/OMInfos?msisdn=${msisdn}`;
    const uriStatus = `/Infos/identification?msisdn=${msisdn}`;

    const promiseTelco = API.get(uriTelco);
    const promiseOm = API.get(uriOm);
    const promiseStatus = API.get(uriStatus);

    return new Promise((resolve, reject) => {
        Promise.all([promiseTelco, promiseOm, promiseStatus])
        .then((res) => {
            console.log("res ", res);
            const infosTelco = res[0].data;
            const infosOm = res[0].data;
            const infosStatus = res[0].data;

            dispatch(getIdentifications({
                infosTelco: infosTelco,
                infosOm: infosOm,
                infosStatus: infosStatus
            }));

            resolve();
        }).catch((error) => {
            if(isDev(error)){
                console.log(error);
            }

            reject(error);
        })
    })


}