import React, { useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import { IntlProvider, FormattedMessage } from "react-intl";
import { ToastContainer } from "react-toastify";

import Home from "./pages/Home/home.page";
import AuthPage from "./pages/Auth/auth.page";
import Layout from "./components/Layout";
import './App.css';
import translations from './config/translations';

import { selectLang } from "./store/reducers/user/user.selectors";
import { selectToken } from "./store/reducers/user/user.selectors";
import setAuthToken from "./utils/setAuthToken";

import "react-toastify/dist/ReactToastify.css";

import routes from "./config/routes";

function App({ lang, token }) {

  useEffect(() => {
    setAuthToken(token);
  }, [token]);

  return (
    <IntlProvider
        messages={translations[lang]}
        locale={lang}
        defaultLocale="fr"
      >
        <>
          {
            <Layout>
              <Switch>
                <Route exact path={routes.root} component={Home} />
                <Route exact path={routes.auth} component={AuthPage} />
              </Switch>        
            </Layout>
          }
          <ToastContainer
            position="top-right"
            autoClose={10000}
            hideProgressBar={true}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </>
      </IntlProvider>
    
  );
}

const mapStateToProps = createStructuredSelector({
  lang: selectLang,
  token: selectToken
});


export default connect(mapStateToProps)(App);
